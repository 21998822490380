import { render, staticRenderFns } from "./searchLayout.vue?vue&type=template&id=3119fc14&"
import script from "./searchLayout.vue?vue&type=script&lang=js&"
export * from "./searchLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SupportWidget: require('/codebuild/output/src1217410078/src/crearistorante-frontend/components/support/SupportWidget.vue').default})
